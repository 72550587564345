<template>
  <div v-show="loading" class="commercials">
    <h2 class="commercials__title">/commercials</h2>
    <div v-for="c in content" :key="c.url" class="commercials__content">
      <iframe
        :src="c.url"
        :title="c.text"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        class="commercials__video"
      ></iframe>
      <div class="commercials__infos">
        <p class="title">
          {{ c.text }}
          <span class="side">
            {{ c.side }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    content: [
      {
        text: `timberland w/ kai-isaiah jamal`,
        side: "| octo operations & radical pr | sept, 2024.",
        url: "https://www.youtube.com/embed/uNx2r0vvBqY",
      },
      {
        text: `backmarket 10 years anniversary`,
        side: "| 10daysinparis | sept, 2024.",
        url: "https://www.youtube.com/embed/Wy_Tjl5OgBo",
      },
    ],
  }),
  mounted() {
    this.loading = true;
  },
  watch: {
    loading(val) {
      if (val) {
        this.$emit("loaded");
      }
    },
  },
};
</script>

<style>
.commercials {
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.commercials__content {
  margin-bottom: 50px;
  width: 100%;
  max-width: 800px;
}
.commercials__title {
  max-width: 800px;
  width: 100%;
  display: block;
  align-self: flex-start;
  font-size: 16px;
  color: #94d900;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 50px;
  font-weight: 200;
}
.commercials__video {
  aspect-ratio: 16 / 9;
  width: 100%;
}
.commercials__infos {
  color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  color: #94d900;
  text-align: center;
  margin-right: 5px;
}
.side {
  color: white;
}
</style>
