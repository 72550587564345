<template>
  <div v-show="loading" class="carousels">
    <CoolLightBox
      :items="photosList"
      :index="index"
      :slideshow="false"
      closeOnClickOutsideMobile
      overlayColor="rgba(0, 0, 0, 1)"
      @close="index = null"
    >
    </CoolLightBox>
    <marquee-text
      :duration="95"
      :paused="pausedR"
      @mouseover="pausedR = true"
      @mouseleave="pausedR = false"
    >
      <img
        v-for="(item, idx) in photos"
        :key="idx"
        :src="getImgUrl(item)"
        :duration="65"
        class="carousels-image"
        @click="findImage(item)"
        @load="loadedR = loadedR + 1"
      />
    </marquee-text>
    <marquee-text
      class="marquee"
      :duration="95"
      :reverse="true"
      :paused="pausedL"
      @mouseover="pausedL = true"
      @mouseleave="pausedL = false"
    >
      <img
        v-for="(item, index) in photosL"
        :key="index"
        :src="getImgUrl(item)"
        class="carousels-image"
        @click="findImage(item)"
        @load="loadedL = loadedL + 1"
      />
    </marquee-text>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import MarqueeText from "vue-marquee-text-component";

export default {
  components: {
    MarqueeText,
    CoolLightBox,
  },
  data: () => ({
    pausedR: false,
    pausedL: false,
    loadingL: false,
    loadingR: false,
    loadedL: 0,
    loadedR: 0,
    index: null,
    photos: [
      "00",
      "11",
      "13",
      "15",
      "17",
      "29",
      "31",
      "00",
      "11",
      "13",
      "15",
      "17",
      "29",
      "31",
      "00",
      "11",
      "13",
      "15",
      "17",
      "29",
      "31",
    ],
    photosL: [
      "01",
      "33",
      "35",
      "47",
      "49",
      "51",
      "53",
      "01",
      "33",
      "35",
      "47",
      "49",
      "51",
      "53",
      "01",
      "33",
      "35",
      "47",
      "49",
      "51",
      "53",
    ],
  }),
  computed: {
    loading: (self) => self.loadingL && self.loadingR,
    photosAll: (self) => [
      { id: "00", idx: self.getImgUrl("00") },
      { id: "01", idx: self.getImgUrl("01") },
      { id: "11", idx: self.getImgUrl("11") },
      { id: "13", idx: self.getImgUrl("13") },
      { id: "15", idx: self.getImgUrl("15") },
      { id: "17", idx: self.getImgUrl("17") },
      { id: "29", idx: self.getImgUrl("29") },
      { id: "31", idx: self.getImgUrl("31") },
      { id: "01", idx: self.getImgUrl("01") },
      { id: "33", idx: self.getImgUrl("33") },
      { id: "35", idx: self.getImgUrl("35") },
      { id: "47", idx: self.getImgUrl("47") },
      { id: "49", idx: self.getImgUrl("49") },
      { id: "51", idx: self.getImgUrl("51") },
      { id: "53", idx: self.getImgUrl("53") },
    ],
    photosList: (self) => self.photosAll.map((el) => el.idx),
  },
  watch: {
    loadedR(val) {
      if (val === this.photos.length * 2) {
        this.loadingL = true;
      }
    },
    loadedL(val) {
      if (val === this.photosL.length * 2) {
        this.loadingR = true;
      }
    },
    loading(val) {
      if (val) {
        this.$emit("loaded");
      }
    },
  },
  methods: {
    getImgUrl(key) {
      const images = require.context("../assets/photos", false, /\.gif/);
      return images("./mobi_" + key + ".gif");
    },
    findImage(key) {
      const index = this.photosAll.findIndex((el) => el.id === key);
      this.index = index;
    },
  },
};
</script>

<style>
.carousels {
  padding-bottom: 25px;
}
.marquee {
  margin-top: 12px;
}
.carousels-image {
  margin: 0 8px;
  max-width: 280px;
}

@media (max-width: 992px) {
  .carousels-image {
    max-width: 200px;
  }
}
</style>
